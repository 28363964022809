@import "src/assets/styles/common";

.faq-accordion {
  background-color: $blue-1;
  border-radius: 8px;
  border: 1px solid $blue-7;
  overflow: hidden;
}
.faq-accordion-header {
  @include flex(center, space-between);
  padding: 24px;

  cursor: pointer;
}
.faq-accordion-collapse {
  overflow: hidden;
  height: 0;

  transition: height 200ms ease-in-out;
}
.faq-accordion-content {
  @include font-lato(16px, 24px);
  color: $blue-9;

  padding: 0 24px 24px;
}
.faq-accordion-title {
  @include font-barlow(22px, 28px, $font-semi-bold);
  color: $blue-10;
}
.faq-accordion-caret {
  color: $blue-10;

  height: 24px;
  width: 24px;

  transition: transform 200ms ease-in-out 0ms;
}
.faq-accordion.__open .faq-accordion-caret {
  transform: rotate(45deg);
}
